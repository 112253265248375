<template>
    <!-- 阅读页 -->
    <reader-page></reader-page>
</template>

<script>
import ReaderPage from "../components/core/index.vue";

export default {
    components: {
        ReaderPage,
    },
};
</script>

<style>
</style>